import { MetaSwiper } from "./meta-settings.js";
import { CountUp } from "./plugins/countUp.js";

const header = document.getElementsByClassName(`header`)[0];

// Header scroll

document.addEventListener("scroll", () => {
  if (window.pageYOffset > 50) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
});

if (window.pageYOffset > 50) {
  header.classList.add("scrolled");
}

// menu handlers

// fix video-poster for ios low battery mode

const mainVideo = document.getElementById("main-video");

const suspendVideoPoster = document.getElementById("suspend-video-poster");

if (mainVideo) {
  setTimeout(() => {
    if (mainVideo.paused) {
      mainVideo.style.display = "none";

      suspendVideoPoster.style.display = "block";
    }
  }, 2000);
}

///check mobile menu show/hide condition

const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");

let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

// $(".dropdown-toggle").click(function (e) {

//   if (isMobileMenuEnable) {

//     //close all opened sub menu

//     $(".menu-item.dropdown.active .dropdown-menu").slideUp({

//       complete: function () {

//         $(this).closest(".dropdown").removeClass("active");

//       },

//     });

//     //open current submenu

//     $(this).closest(".menu-item.dropdown").toggleClass("active");

//     if ($(this).closest(".menu-item.dropdown").hasClass("active")) {

//       e.preventDefault();

//       $(this).next(".dropdown-menu").slideDown();

//     }

//   }

// });

// add FillGap for fixed header, so it will not jump when menu is opened

if (header?.classList.contains("fixed")) {
  scrollLock.addFillGapSelector(".header.fixed");
}

// toggle menu handler

function menuToggle() {
  $(".menu-toggle").toggleClass("active");

  $(".navbar-nav").toggleClass("active");

  $(".header-close-wrapper").toggleClass("active");

  // LockScroll when burger open and enable when closed and enable scroll on menu

  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}

//menu update function

function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;

  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");

    $(".header-close-wrapper").removeClass("active");

    $(".menu-item.active").removeClass("active");

    $(".navbar-nav").removeClass("active");

    $(".menu-toggle").removeClass("active");

    // LockScroll when burger open and enable when closed

    scrollLock.enablePageScroll();
  }
}

$(window).on("resize orientationchange", updateMenu);

// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles

$(".header-close-wrapper").click(menuToggle); //menu toggles

const counts = [...document.getElementsByClassName("statistic__block-count")];

if (counts.length) {
  counts.forEach((count, idx) => {
    const countupNum = count.querySelector(".count-up").innerHTML;

    count.id = `count-up-${idx + 1}`;

    const num = parseFloat(countupNum);
    const suffix = countupNum.replace(num, "");
    const isFloat = num % 1 !== 0;

    new CountUp(count.id, num, {
      enableScrollSpy: true,
      scrollSpyOnce: true,
      decimalPlaces: isFloat ? 1 : 0,
      suffix,
    });
  });
}

const stickyElements = document.querySelectorAll(".sticky");
Stickyfill.forceSticky();
Stickyfill.add(stickyElements);

const chatSection = document.querySelector(".chat-section");

if (chatSection) {
  const formTextarea = chatSection.querySelector(".textarea");

  autosize(formTextarea);
}

const rellax = new Rellax(".rellax", {
  speed: -2,

  center: true,

  wrapper: null,

  round: true,

  vertical: true,

  horizontal: false,

  breakpoints: [320, 768, 1201],
});

// BOLD Lottie init (Має бути в самому кінці файлу)

// lottie.loadAnimation({

//   container: document.getElementById(`bold-credits`),

//   renderer: "svg",

//   loop: true,

//   autoplay: true,

//   path: "./js/lottie/BOLD-white-logo.json",

// });

const progressSection = document.querySelector(".progress-section");

if (progressSection) {
  const scrollFillElement = progressSection.querySelector(
    ".progress-line .fill"
  );

  // Scroll progress function

  function scrollProgress(section, fillElement, startIndex = 0.25) {
    let sectionTopPosition =
      section.offsetTop - window.innerHeight + window.innerHeight * startIndex;

    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const sectionHeight = section.scrollHeight;

    const scrolled =
      ((windowScroll - sectionTopPosition) / sectionHeight) * 100;

    let windowWidth = window.innerWidth;

    window.addEventListener("resize", function (e) {
      if (windowWidth === window.innerWidth) return;

      windowWidth = window.innerWidth;

      sectionTopPosition =
        section.offsetTop -
        window.innerHeight +
        window.innerHeight * startIndex;
    });

    scrollFillElement.style.height = scrolled + "%";

    window.onscroll = function () {
      scrollProgress(section, fillElement);
    };
  }

  scrollProgress(progressSection, scrollFillElement);
}

// AOS init

AOS.init({
  startEvent: "load",

  disableMutationObserver: false,
});

AOS.refresh(true);

// select2

$(".input__select").select2();

// swipers

// Intro Swiper

const introSection = document.getElementsByClassName(`intro-section`)[0];

if (introSection) {
  MetaSwiper(".intro-swiper", {
    spaceBetween: 0,

    speed: 400,

    grabCursor: true,

    slidesPerView: 1,

    grid: {
      rows: 2,

      fill: "row",
    },

    loop: false,

    autoplay: {
      delay: 3000,

      disableOnInteraction: false,

      pauseOnMouseEnter: true,
    },

    breakpoints: {
      768: {
        spaceBetween: 16,

        slidesPerView: "auto",

        grid: {
          rows: 1,
        },
      },
    },
  });
}

// What included Swiper

const whatIncludeSection = document.getElementsByClassName(
  `what-included-section`
)[0];

if (whatIncludeSection) {
  MetaSwiper(".what-included-slider", {
    spaceBetween: 16,

    speed: 400,

    slidesPerView: 1,

    loop: true,

    autoplay: {
      delay: 5000,
    },

    navigation: {
      nextEl: ".what-included-slider__nav-btn.right",

      prevEl: ".what-included-slider__nav-btn.left",
    },
  });
}

// 5 reason section Swipers

const fiveReasonsSection = document.querySelector(".five-reasons-section");

if (fiveReasonsSection) {
  const titleSwiper = new Swiper(".fr-title-swiper", {
    spaceBetween: 16,

    slidesPerView: 1,

    allowTouchMove: false,

    rewind: true,

    navigation: {
      nextEl: ".fr-descr-swiper__nav-btn.right",

      prevEl: ".fr-descr-swiper__nav-btn.left",
    },

    loop: false,
  });

  const descrSwiper = new Swiper(".fr-descr-swiper", {
    slidesPerView: 1,

    loop: false,

    effect: "fade",

    allowTouchMove: false,

    rewind: true,

    fadeEffect: {
      crossFade: true,
    },

    navigation: {
      nextEl: ".fr-descr-swiper__nav-btn.right",

      prevEl: ".fr-descr-swiper__nav-btn.left",
    },
  });

  const otherSwiper = new Swiper(".fr-other-swiper", {
    spaceBetween: 16,

    slidesPerView: 1,

    allowTouchMove: false,

    rewind: true,

    loop: false,

    navigation: {
      nextEl: ".fr-descr-swiper__nav-btn.right",

      prevEl: ".fr-descr-swiper__nav-btn.left",
    },
  });
}

// other articles

const otherArticles = document.querySelector(".other-articles-section");

if (otherArticles) {
  const slidesCount = otherArticles.querySelectorAll(
    ".other-articles-swiper .swiper-slide"
  ).length;

  const otherArticlesSwiper = new Swiper(".other-articles-swiper", {
    slidesPerView: 1,

    spaceBetween: 20,

    grabCursor: true,

    loop: true,

    autoplay: {
      delay: 3000,

      pauseOnMouseEnter: true,

      disableOnInteraction: false,
    },

    navigation: {
      nextEl: ".other-articles-swiper__nav-btn.right",

      prevEl: ".other-articles-swiper__nav-btn.left",
    },

    breakpoints: {
      768: {
        slidesPerView: 2,

        loop: slidesCount > 2,
      },

      1280: {
        slidesPerView: 3,

        spaceBetween: 51,

        loop: slidesCount > 3,
      },
    },
  });
}

// other jobs

const otherJobs = document.querySelector(".other-jobs-section");

if (otherJobs) {
  const slidesCount = otherJobs.querySelectorAll(
    ".other-jobs-swiper .swiper-slide"
  ).length;

  const otherJobsSwiper = new Swiper(".other-jobs-swiper", {
    slidesPerView: 1,

    spaceBetween: 100,

    grabCursor: true,

    loop: true,

    autoplay: {
      delay: 3000,

      pauseOnMouseEnter: true,

      disableOnInteraction: false,
    },

    navigation: {
      nextEl: ".other-jobs-swiper__nav-btn.right",

      prevEl: ".other-jobs-swiper__nav-btn.left",
    },

    breakpoints: {
      768: {
        slidesPerView: 2,

        loop: slidesCount > 2,
      },

      1280: {
        slidesPerView: 3,

        spaceBetween: 158,

        loop: slidesCount > 3,
      },
    },
  });
}

// portfolio

const portfolioSection = document.querySelector(".portfolio-section");

if (portfolioSection) {
  const logoArr = portfolioSection.querySelectorAll(".portfolio__client-logo");

  let activeSlideId = 0;

  const logoSwiper = MetaSwiper(".portfolio__clients-slider", {
    slidesPerView: "auto",
    spaceBetween: 37,
    grabCursor: true,
    freeMode: {
      enabled: true,
      sticky: true,
    },
    loop: false,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },

    breakpoints: {
      901: {
        slidesPerView: 3,
        spaceBetween: 20,
        freeMode: false,
        autoplay: false,
        grid: {
          rows: 3,
          fill: "row",
        },
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 37,
        freeMode: false,
        autoplay: false,
        grid: {
          rows: 3,
          fill: "row",
        },
      },
    },
  });

  const descrSlider = new Swiper(".portfolio__descr-slider", {
    slidesPerView: 1,

    allowTouchMove: false,

    autoHeight: true,

    effect: "fade",

    autoplay: {
      delay: 333000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },

    fadeEffect: {
      crossFade: true,
    },

    on: {
      slideChange: ({ realIndex }) => {
        logoSwiper.slideTo(Number(realIndex));

        logoArr[activeSlideId].classList.remove("active");

        logoArr[realIndex].classList.add("active");

        activeSlideId = realIndex;
      },
    },

    breakpoints: {
      901: {
        autoplay: {
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
      },
    },
  });

  const handleLogoClick = (elem) => {
    const { slideId } = elem.dataset;

    logoArr[activeSlideId].classList.remove("active");

    logoArr[slideId].classList.add("active");

    descrSlider.slideTo(Number(slideId));
    logoSwiper.slideTo(Number(slideId));

    activeSlideId = slideId;
  };

  logoArr.forEach((elem) => {
    elem.addEventListener("click", () => {
      handleLogoClick(elem);
    });
  });
}

const searchBtn = document.querySelector(".media-hub__search-form-btn");

if (searchBtn) {
  const firstSection = document.querySelector(".first-section-inner");
  const scrollToContent = () => {
    window.scroll({
      top: firstSection.scrollHeight - 50,
      left: 0,
      behavior: "smooth",
    });
  };

  searchBtn.addEventListener("click", scrollToContent);
}
